<template>
  <div class="g_page_box report-detail">
     <van-dialog v-model="show" title="" :show-confirm-button="false">
      <div class="report-button">
        <!-- <div @click="history_list" class="no-data-dialog">历史报告</div> -->
        <div class="no-data-text">分析了您最近的检验报告暂无风险</div>
        <div class="confim" @click="confim">确认</div>
      </div>
    </van-dialog>
    <div class="g_main_content no-data" v-if="is_show2">
      <RadarLoading/>
    </div>
    <template v-else>
      <div class="g_main_content report" >
        <div class="part-1">
          <div class="text-1">
            <div class="text-1-top">AI医生分析了您的检验报告</div>
            <div class="text-1-bottom">检验报告时间 <span v-if="data.start_date">{{format_date(data.start_date,'yyyy年MM月dd日')}}至</span> {{format_date(data.end_date,'yyyy年MM月dd日')}}</div>
          </div>
          <!-- <div class="history" @click="history_list">历史报告</div> -->
          <img class="wave-big" src="../../assets/img/wave-big.png" alt="" height="100%">
          <img class="wave-small" src="../../assets/img/wave-small.png" alt="" height="100%">
        </div>
        <div class="part-2" @click="jup_lab_list">
          <van-badge :content="laboratories.length" max="99">
            <img width="46" src="../../assets/img/message.png"/>
          </van-badge>
          <div class="text-2">
            <div class="text-2-top">您的检验报告出现{{laboratories.length}}个异常项</div>
            <div class="text-2-bottom">提示以下系统存在健康风险:</div>
          </div>
        </div>
        <div class="part-3">
          <div class="human-1">
            <Human :systems_arr="systems_arr"/>
          </div>
          <div class="system-info" v-if="systems_arr.length>0">
            <img style="margin-right: 6px;" width="16" height="16" src="../../assets/img/report-system.png"/>
            <div style="flex:1;">AI医生进行报告解读后，提醒您在<span> {{systems_arr.join('、')}} </span>方面有一定的风险。</div>
          </div>
        </div>
        <div style="height: 15px; background:RGBA(247, 247, 248, 1);"></div>
        <div class="part-4">
          <div class="text-4">
            <div class="item-icon">
              <div class="inner"/>
            </div>
            <div class="text-4-top">
            AI医生分析您的检验报告,建议您尽快前往医院排除以下疾病风险
              <div>
                以下{{diagnosis.length>=3 ? 3 : diagnosis.length}}个疾病风险最高,点击查看详细分析
              </div>
            </div>
          </div>
          <div @click="jup_diagnosis(i)" v-for="(i,index) in diagnosis" :key="index + 'risk'">
            <div v-if="index<3 || (more&&index>=3)" :class="['health-risks-i',{'health-risks-i-middel': i.reliable === 1}]">
              <div :class="['degree',{'middle': i.reliable === 1}]">{{index + 1}}</div>
              <div class="name-content">
                <div class="name">{{i.name}}</div>
                <div class="advise" v-if="i.suggestions&&i.suggestions.length>0">建议考虑：{{hander_suggestions(i.suggestions)}}</div>
              </div>
              <div v-if="i.system&&i.system.length>0">
                <img width="23" style="margin-right: 5px;" v-for="el in i.system" :key="el" :src="`./img/system/${system_list.indexOf(el) + 1}.png`"/>
              </div>
            </div>
          </div>
          <div class="look-more" v-if="diagnosis.length > 3 && !more" @click="look_more">点击查看其他{{diagnosis.length - 3}}个疑似疾病</div>
        </div>
      </div>
      <div class="foot" @click="order">
        <div class="item-icon-1">
            <div class="inner">
              <img width="30" src="../../assets/img/report-ai.png"/>
              AI医生
            </div>
          </div>
          <div class="foot-text">您可以线下就诊或继续</div>
          <div class="online">在线问诊</div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapMutations,mapState,mapActions } from 'vuex'
import { getSession } from '../../utils/session'
import { format_date,format_decimal} from '../../utils/format'
import {system_list} from './config'
import Human from './HumanImg'
import RadarLoading from './RadarLoading'

export default {
  data () {
    return {
      authToken: getSession(),
      system_list,
      more: false,
      show: false,
    }
  },
  computed: {
    ...mapState('report', {
      data: state => state.data,
      loading: state => state.loading,
    }),
    is_show() {
      let {loading,data} = this
      return !loading&&(data&&data.system_risk.diagnosis.length===0 || !data)
    },
    is_show2() {
      let {loading,data} = this
      return loading || (!loading&&data&&data.system_risk.diagnosis.length===0 || !data)
    },
    laboratories() {
      let arr = []
      let laboratories = this.data&&this.data.laboratories || []
      arr = laboratories.filter(i=>{
        return i.standard_result
      })
      return arr
    },
    systems_arr(){
      let arr = []
      let systems = this.data&&this.data.system_risk.systems || []
      for(let i of systems){
        if(i.rate) arr.push(i.name)
      }
      return arr
    },
    diagnosis() {
      return this.data&&this.data.system_risk.diagnosis || []
    }
  },
  components: {
    Human,
    RadarLoading
  },
  watch: {
    is_show(){
      this.show = this.is_show
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      const {order_id} = vm.$route.query
      if (from.path === '/diagnosis-detail' || from.path === '/lab-list') {
        return
      }//确认返回不需要重新加载
      vm.clearState()
      vm.search_report(order_id)
    })
  },
  methods: {
    format_date,
    format_decimal,
    ...mapMutations('report', [
      'clearState'
    ]),
    ...mapActions('report', [
      'search_report',
    ]),
    hander_reliable() {
      let index = 0
      for(let i of this.diagnosis) {
        if(i.reliable===1) {
          return index
        }
        index++
      }
    },
    jup_diagnosis(i){
      let id = this.data.id
      this.$router.push({
        path:'/diagnosis-detail',
        query: {
          id,
          diagnosis_name: i.name
        }
      })
    },
    jup_lab_list(){
      this.$router.push({
        path:'/lab-list'
      })
    },
    history_list () {
      this.$router.push({
        path:'/report-list'
      })
    },
    hander_suggestions(array) {
      let arr = []
      for(let i of array) {
        arr.push(i.name)
      }
      return arr.join('/')
    },
    look_more() {
      this.more = true
    },
    async order () {
      // let url = 'https://wechat.xmsmjk.com/wechatsehr/wx/#/bookGuide/doctorList?deptCode=1010200&orgCode=350211A1002&orgID=3'
      // window.open(url)
    },
    confim() {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped>
.report {
  padding: 0px 0px 25px;
  position: relative;
}
.no-data {
 display: flex;
 flex-direction: column;
 align-items: center;
 font-size: 15px;
font-weight: 400;
color: #666666;
line-height: 21px
}
@keyframes move {
  0 {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% + 100vw));
  }
}
.part-1 {
  height: 130px;
  border-radius:0 0 5% 5%;
  padding: 30px 12px 0;
  display: flex;
  background: rgba(5, 17, 178, 1);
  position: relative;
  overflow: hidden;
  display: flex;
  & .wave-big,
  & .wave-small {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-bottom: 0;
    animation: move 100s linear infinite;
  }
  & .wave-small {
    animation: move 150s linear infinite;
  }
  & .history {
    width: 72px;
    height: 28px;
    border: 1px solid #FFFFFF;
    border-radius: 14px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #FFFFFF;
    text-align: center;
    line-height: 28px;
    z-index: 2;
    margin-top: 5px;
  }
  & .text-1 {
    color: #FFFFFF;
    z-index: 2;
    flex:1;
    & .text-1-top {
       font-size: 16px;
      font-weight: 500;
      line-height: 23px;
    }
    & .text-1-bottom {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      & >img {
        margin-right: 4px;
      }
    }
  }
}
.part-2 {
  height: 68px;
  background: #FFFBEC;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 17px;
  position: absolute;
  top: 96px;
  left: 16px;
  right: 16px;
  z-index: 3;
  & .text-2 {
    padding-left: 17px;
    & .text-2-top {
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      color: #242938;
      padding-bottom: 3px;
    }
    & .text-2-bottom {
      font-size: 12px;
      font-weight: 400;
      line-height: 7px;
      color: #22242A;
    }
  }
}
.part-3 {
  padding: 0 12px;
  margin-top: 54px;
  & .human-1 {
    width:100%;
  }
  & .system-info {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: #242938;
    display: flex;
    padding:6px 7px;
    background: #F4F9FE;
    border-radius: 1px;
    margin: 30px 38px 30px 45px;
    & span {
      font-weight: bold;
      color:rgba(24, 144, 255, 1);
    }
  }
}
.part-4 {
  padding:30px 17px 0;
  & .high-text {
    font-size: 16px;
    font-weight: bold;
    line-height: 23px;
    color: #242938;
    padding: 20px 36px 11px;
  }
  & .name-1 {
    align-items: center;
    display: flex;
    margin:0 36px 9px;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    color: #242938;
    & .line {
      background: #0088FF;
      border-radius: 14px;
      width: 7px;
      height: 16px;
      margin-right: 9px;
    }
  }
  & .t-text {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 36px;
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      color: #1890FF;
     & .system {
      display: flex;
      align-items: center;
      & img {
        margin-right: 9px;
      }
     }
  }

  & .health-risks-i {
    margin:0 36px 12px;
    display: flex;
    align-items: center;
    background: #FFF2F2;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.09);
    font-size: 14px;
    font-weight: 500;
    color: #242938;
    min-height: 84px;
    padding-right: 9px;
    position: relative;
    padding: 0 14px;
    height: 100%;
    padding: 0 14px;
    background-image: url("../../assets/img/disease.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    /* &.health-risks-i-middel {
      background: #FFFAED;
    } */
    & .degree {
      min-width: 27px;
      height: 27px;
      background: #FFFFFF;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      color: #ED8393;
    }
    & .name-content {
      z-index: 1;
      flex:1;
      min-height: 84px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding:0px 9px 0 14px;
      & .name {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #242938;
      }
      & .advise {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #666666;
        padding-top: 1px;
      }
    }
  }
  & .look-more {
    margin: 0 36px 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #1890FF;
  }
}
.text-4 {
  display: flex;
  padding-bottom: 15px;
  & .item-icon {
    width: 26px;
    height: 26px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
    border-radius: 50%;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 13px;
    & .inner {
      width: 16px;
      height: 16px;
      background: #0088FF;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
      border-radius: 50%;
    }
  }
  & .text-4-top {
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    color: #242938;
    flex:1;
    & >div {
      font-size: 13px;
      font-weight: 400;
    }
  }

}


.foot {
  z-index: 1;
  height: 90px;
  padding:0 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  position: relative;
  &::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    border: 1 solid #ebedf0;
    transform: scale(0.5);
  }
  & .item-icon-1 {
    width: 64px;
    height: 64px;
    background: #FFFFFF;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    font-weight: 400;
    line-height: 13px;
    color: #FFFFFF;
    margin-top: -10px;
    & .inner {
      width: 55px;
      height: 55px;
      background: #0088FF;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  & .foot-text {
    font-size: 14px;
    font-weight: 400;
    padding-top: 22px;
    color: #242938;
    line-height: 16px;
    flex: 1;
    padding-left: 15px;
  }
  & .online {
    width: 92px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1890FF;
    border-radius: 15px;
    font-size: 14px;
    color: #FFFFFF;
    margin-top: 14px;
  }
}
.report-button {
  padding: 20px 35px;
  & .no-data-dialog {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #0088FF;
    text-align: right;
    padding-bottom: 19px;
  }
  & .no-data-text{
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: #242938;
    padding-bottom: 39px;
  }
  & .confim {
    width: 254px;
    height: 40px;
    background: #1890FF;
    opacity: 1;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #FFFFFF;
  }
}
</style>
<style>
</style>

