<template>
  <div class="loading-1">
    <div class="text1">检优正在扫描您的健康风险</div>
    <div class="radar-1">
      <img src="../../assets/img/systems-bg/human.png"/>
      <div class="yuan1"/>
      <div class="radar"></div>
    </div>
    <div  class="text">
      <div class="text2">正在读取分析您的检验报告</div>
      <!-- <div class="text3"><img width="16" src="../../assets/img/time.png"/> 化验报告时间 {{format_date(start_date,'yyyy年MM月dd日')}}至{{format_date(end_date,'yyyy年MM月dd日')}}</div> -->
    </div>

  </div>
</template>
<script>
import { format_date } from '../../utils/format'
export default {
  data () {
    // let time_to = new Date()
    // let time_from = new Date(time_to.getTime() - 1000 * 60 * 60 * 24 * 21)
    return {
      start_date: null,
      end_date: null
    }
  },
  created() {
    const{start_date,end_date} = this.$route.query
    this.start_date = start_date
    this.end_date = end_date
  },
  methods: {
    format_date
  }
}
</script>
<style>
.loading-1 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:50px 12px 38px;
  text-align: center;
}
.radar-1 {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 362px;
  display: flex;
  justify-content: center;
  align-items: center;
  & .yuan1 {
    height: 320px;
    width: 320px;
    position: absolute;
    border: 1px solid rgba(254, 171, 203, 0.18);
    box-shadow: 0px 2px 2px rgba(255, 0, 0, 0.34);
    border-radius: 50%;
  }
  & img {
    position: absolute;
    height: 100%;
    max-width: 347px;
    top: -30px;
  }
}
.text1 {
  font-size: 19px;
  font-weight: 500;
  line-height: 27px;
  color: #242938;
  padding-bottom: 36px;
}
.text {
  flex:1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  & .text2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #242938;
    padding-bottom: 27px;
  }
  & .text3 {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color:rgba(36, 41, 56, 0.45);
    display: flex;
    align-items: center;
    & img {
      margin-right:6px;
    }
  }
}


@keyframes radar-beam {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes blips {
  14% {
    background: radial-gradient(
      4vmin circle at 75% 70%,
      #ffffff 10%,
      #ffd000 30%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  14.0002% {
    background: radial-gradient(
        4vmin circle at 75% 70%,
        #ffffff 10%,
        #ffd000 30%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(
        4vmin circle at 63% 72%,
        #ffffff 10%,
        #ffd000 30%,
        rgba(255, 255, 255, 0) 100%
      );
  }
  25% {
    background: radial-gradient(
        4vmin circle at 75% 70%,
        #ffffff 10%,
        #ffd000 30%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(
        4vmin circle at 63% 72%,
        #ffffff 10%,
        #ffd000 30%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(
        4vmin circle at 56% 86%,
        #ffffff 10%,
        #ffd000 30%,
        rgba(255, 255, 255, 0) 100%
      );
  }
  26% {
    background: radial-gradient(
        4vmin circle at 75% 70%,
        #ffffff 10%,
        #ffd000 30%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(
        4vmin circle at 63% 72%,
        #ffffff 10%,
        #ffd000 30%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(
        4vmin circle at 56% 86%,
        #ffffff 10%,
        #ffd000 30%,
        rgba(255, 255, 255, 0) 100%
      );
    opacity: 1;
  }
  100% {
    background: radial-gradient(
        4vmin circle at 75% 70%,
        #ffffff 10%,
        #ffd000 30%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(
        4vmin circle at 63% 72%,
        #ffffff 10%,
        #ffd000 30%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(
        4vmin circle at 56% 86%,
        #ffffff 10%,
        #ffd000 30%,
        rgba(255, 255, 255, 0) 100%
      );
    opacity: 0;
  }
}

.radar {
  width: 220px;
  height: 220px;
  opacity: 0.4;
  background: -webkit-repeating-radial-gradient(
    rgba(240, 48, 151, 0.4) 50%,
    rgba(255, 237, 247, 0) 78%
  );
  transform: translate(0%, 0%);
  border-radius: 50%;
  overflow: hidden;
  &:before {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: blips 5s infinite;
    animation-timing-function: linear;
    animation-delay: 1.4s;
  }
  &:after {
    content: " ";
    display: block;
    background-image: linear-gradient(
      44deg,
      rgba(255, 0, 0, 0) 50%,
      #ff0077 100%
    );
    width: 50%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    animation: radar-beam 5s infinite;
    animation-timing-function: linear;
    transform-origin: bottom right;
    border-radius: 100% 0 0 0;
  }
}
</style>
