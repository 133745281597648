<template>
  <div class="human">
    <img src="../../assets/img/systems-bg/bg.png"/>
    <img src="../../assets/img/systems-bg/human.png"/>
    <img v-for="i in arr" :key="i" :src="`./img/systems-bg/${i}.png`"/>
  </div>
</template>
<script>
import {system_list} from './config'
export default {
  props:{
    systems_arr:{
      type: Array,
      default: ()=>[]
    }
  },
  data () {
    return {
      system_list
    }
  },
  computed: {
    arr() {
      let arr = []
      for(let i of this.systems_arr) {
        arr.push(this.system_list.indexOf(i)+1)
      }
      return arr
    }
  },
  components: {
  },
  watch: {

  },
  created () {
  },
  methods: {

  }
}
</script>
<style scoped>
  .human {
    width: 100%;
    height: 362px;
    position: relative;
    display: flex;
    justify-content: center;
   & img {
     position: absolute;
     max-width: 347px;
     height:100%;
     z-index: 2;
   }
  }

</style>

